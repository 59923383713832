import dayjs from "dayjs"
import { ImageSet, ImageSetFields } from "~~/modules/nuxt3-module-doyac/composables/common"
import { extractDataOrFailure, useDYFetchWithToken } from "~~/modules/nuxt3-module-doyac/composables/fetch"

export interface OpenFormData {
  name: string
  pcs: string
  introduce: string
  safePhone?: boolean
  snsId?: string
  snsType?: string
}

export interface RequestFormCommonMember {
  no: number
  profileImage: ImageSet
  // name: string
  sex: string
  protectedName: string
  wrong_number: boolean
}

export type RequestFormCommonMemberFields = Exclude<keyof RequestFormCommonMember, 'profileImage'> | { profileImage: ImageSetFields[] } | '$all'

export type RequestFormListItem = RequestForm
  & { commonMember: RequestFormCommonMember }

export interface RequestForm {
  no: number
  /**
    case 7 : return '7만원 이상'; 근데 이제는 그 이상의 가격도 입력할 수 있게 되어서 이렇게 안할듯?
    case 0 : return '협의';
    default : return lessonPay + '만원 (회당)';
   */
  lessonPay: number
  /**
   * 1은 협의가능, 0은 불가
   */
  isPayNego: number
  gender: number
  hit: number
  /**
   * 레슨, 채용 등 목적
   */
  purpose: string
  /**
   * 1은 연결가능, 2는 연결완료, 3은 비공개
   */
  status: number
  subjects: string
  commonMember: RequestFormCommonMember
}

export interface Answer {
  answer: string
  no: number
  questionNo: number
  requestFormPk: number
}

export interface Question {
  no: number
  public_title: string | null
  questionType: string
  rank: number
  title: string
}

export interface Research {
  description: string
  insertedTime: string
  no: number
  title: string
}

export interface RequestFormDetailQNA {
  answer: Answer
  question: Question
}

export interface RequestFormActiveItems {
  endBold: string | null
  endColor: string | null
  endFocus: string | null
  endFocusBorder: string | null
  endIcon: string | null
  endJung: string | null
  endPre: string | null
  hasBold: boolean
  hasColor: boolean
  hasIcon: boolean
  isFocus: boolean
  isFocusBorder: boolean
  isJung: boolean
  isPre: boolean
  textColor: string
  textIcon: string
}
export type RequestFormActiveItemsFields = keyof RequestFormActiveItems | '$all'

export interface RequestFormDetail extends RequestForm {
  areaHtmlize: string
  backgroundImage: ImageSet
  formType: 'teacher' | 'student'
  gender: number
  lastLogin: string
  research: Research
  statusHangul: string
  title: string

  activeItems: RequestFormActiveItems
  qnas: RequestFormDetailQNA[]
}

export type RequestFormBrowseType = 'login' | 'hit' | 'highPay' | 'lowPay'

export interface RequestFormListParams {
  /**
 * 1은 연결가능, 2는 연결완료, 3은 비공개
 */
  status?: string | number
  subjects?: string | string[]
  page?: number
  perPage?: number
  hopeAreaSido?: string
  hopeAreaSigungu?: string | string[]
  browseType?: RequestFormBrowseType
  fromPay?: number
  toPay?: number
  gender?: number
}

export function useGWRequestFormDetailManager(item: RequestFormDetail) {
  const dayFrom = computed(() => {
    const date1 = dayjs()
    const diff = date1.diff(item.lastLogin,'d')
  
    if(diff === 0) {
      return 'TODAY'
    }else if(diff < 3) {
      return '3일이내'
    }else if(diff < 7) {
      return '7일이내'
    }else if(diff < 14) {
      return '2주이내'
    }else if(diff < 30) {
      return '1달이내'
    }else if(diff < 3) {
      return '1달이상'
    }
  })

  const targetTerm = computed(()=>{
    if(item.formType === 'student') {
      return item.purpose === '채용' ? '' : '레슨생'
    }

    return '전문가'
  })

  return {
    dayFrom, targetTerm
  }
}

export function useGWRequestFormManager(item: RequestForm) {
  const defaultImage = () => {
    return "/doyacimpl/mini_logo.svg"
  }

  return {
    defaultImage
  }
}

export function useGWRequestForms() {
  /**
 * 해당 학생 요청서를 열람할 수 있는지 가부 체크
 * @param targetRequestForm 
 * @returns 
 */
  const availableOpenStudent = async (targetRequestForm: number) => {
    const response = await useDYFetchWithToken<{ success: boolean, code: string, remainTicket: number, remainView: number }>(`/mer/api/request-forms/available/student/${targetRequestForm}`, { method: 'post' })
    return extractDataOrFailure(response)
  }

  /**
   * 해당 선생님 요청서를 열람할 수 있는지 가부 체크
   * @param targetRequestForm 
   * @returns 
   */
  const availableOpenTeacher = async (targetRequestForm: number) => {
    const response = await useDYFetchWithToken<{ success: boolean, code: string, remainTicket: number }>(`/mer/api/request-forms/available/teacher/${targetRequestForm}`, { method: 'post' })
    return extractDataOrFailure(response)
  }

  const hasForm = async () => {
    const response = await useDYFetchWithToken<{success:boolean}>(`/mer/api/request-forms/has`, { method: 'get' })
    return extractDataOrFailure(response)
  }

  /**
   * 성공 외에는 전부 abort로 리스폰스가 옴. catch로 빠질것
   * @param target 
   * @param targetRequestFormNo 
   * @returns 
   */
  const viewForm = async (target: 'teacher' | 'student', targetRequestFormNo: number) => {
    const response = await useDYFetchWithToken<{ success: boolean, message: string, data: OpenFormData }>(`/mer/api/request-forms/${target}/${targetRequestFormNo}`, { method: 'post' })
    return extractDataOrFailure(response)
  }

  return {
    availableOpenStudent, availableOpenTeacher, hasForm, viewForm
  }
}